import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { media } from '../lib/media'
import { rolling } from '../lib/transition'

const Block = styled.div`
	width: 100%;
	max-width: 1000px;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 70px;
	margin: 0 auto;

	h2 {
		font-size: 16px;
		font-weight: bold;
		color: #00498c;
		letter-spacing: -2px;
	}


	h3 {
		font-size: 16px;
		color: #666666;
	}

	.fp-scrollable {
		height: 100% !important;
	}
`

const TitleArea = styled.div`
	display: flex;
	justify-content: center;
`

const EquipementArea = styled.div`
	width: 100%;
	height: 90vh;
	display: flex;
	flex-direction: column;
	padding-top: 12px;
`

const MainArea = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 20px 0 20px;

	${media.mobile} {
		flex-direction: column;
		gap: 10px;
	}
`

const MainImageArea = styled.div`
	width: 50%;
	height: 400px;
	max-height: 100%;
	background-image: url(${props => props.url});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	border: 1px solid gray;
	border-radius: 12px;

	${media.mobile} {
		width: 90%;
		height: 280px;
	}
`

const ImageListArea = styled.div`
	width: 50%;
	height: 100%;
	display: flex;
	flex-wrap: wrap;

	${media.mobile} {
		width: 90%;
		height: 80px;
		flex-wrap: nowrap;
		gap: 10px;
	}
`

const WaiteImageArea = styled.div`
	width: 50%;
	height: 198px;
	max-height: 100%;
	background-image: url(${props => props.url});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	border: 1px solid gray;
	border-radius: 12px;
	opacity: 0.3;
`

const AutoSlideArea = styled.div`
	display: grid;
	width: 100vw;
	max-width: 100%;
	grid-template-columns: repeat(16, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: 4px;
	transform: translateX(0);
	animation: ${rolling} 30s infinite ease-in;
`

const AutoSlideImage = styled.div`
	width: 80px;
	height: 80px;
	background-image: url(${props => props.url});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
`

const EquipName = styled.div`
	font-size: 12px;
`

const imageList = [
	{ id: 0, url: '/images/ct1.png' },
	{ id: 1, url: '/images/ultrasonic.png' },
	{ id: 2, url: '/images/slit.png' },
	{ id: 3, url: '/images/ligasure.png' },
	{ id: 4, url: '/images/eticon.png' },
]

const subImageList = [
	{ id: 0, url: '/images/equipment/sub/0.jpg', description: '검이경' },
	{ id: 1, url: '/images/equipment/sub/1.jpg', description: '광학현미경' },
	{ id: 2, url: '/images/equipment/sub/2.jpg', description: '덴탈유닛' },
	{ id: 3, url: '/images/equipment/sub/3.jpg', description: '슬릿현미경' },
	{ id: 4, url: '/images/equipment/sub/4.jpg', description: '레이저치료기' },
	{ id: 5, url: '/images/equipment/sub/5.jpg', description: '산소발생기' },
	{ id: 6, url: '/images/equipment/sub/6.jpg', description: '환축모니터링기' },
	{ id: 7, url: '/images/equipment/sub/7.jpg', description: '심전도기' },
	{ id: 8, url: '/images/equipment/sub/8.jpg', description: '안압계' },
	{ id: 9, url: '/images/equipment/sub/9.jpg', description: '에티콘' },
	{ id: 10, url: '/images/equipment/sub/10.jpg', description: '전자내시경' },
	{ id: 11, url: '/images/equipment/sub/11.jpg', description: '요분석기' },
	{ id: 12, url: '/images/equipment/sub/12.jpg', description: '요비중계' },
	{ id: 13, url: '/images/equipment/sub/13.jpg', description: '자동혈구계산기' },
	{ id: 14, url: '/images/equipment/sub/14.jpg', description: '전기수술기' },
	{ id: 15, url: '/images/equipment/sub/15.jpg', description: '치과엑스레이' },
	{ id: 16, url: '/images/equipment/sub/16.jpg', description: '혈청화학분석기' },
	{ id: 17, url: '/images/equipment/sub/17.jpg', description: '호르몬분석기' },
	{ id: 18, url: '/images/equipment/sub/18.jpg', description: '호흡마취기' },
	{ id: 19, url: '/images/equipment/sub/19.jpg', description: '감시모니터' },
	{ id: 20, url: '/images/equipment/sub/20.jpg', description: '신경치료기' },
	{ id: 21, url: '/images/equipment/sub/21.jpg', description: '냉동치료기' },
	{ id: 22, url: '/images/equipment/sub/22.jpg', description: '치과치료기' },
	{ id: 23, url: '/images/equipment/sub/23.jpg', description: '혈압계' },
	{ id: 24, url: '/images/equipment/sub/24.jpg', description: '주입펌프' },
	{ id: 25, url: '/images/equipment/sub/25.jpg', description: '광중합기' },
	{ id: 26, url: '/images/equipment/sub/26.jpg', description: '전기수술기' },
	{ id: 27, url: '/images/equipment/sub/27.jpg', description: '신경치료기' },
	{ id: 28, url: '/images/equipment/sub/28.jpg', description: '석션펌프' },
	{ id: 29, url: '/images/equipment/sub/29.jpg', description: '시린지펌프' },
	{ id: 30, url: '/images/equipment/sub/30.jpg', description: '램프' },
]

const Equipment = () => {
	const [mainImage, setMainImage] = useState(imageList[0])
	const [imageWaitList, setimageWaitList] = useState(imageList.filter(image => image.id !== 0))
	const slideRef = useRef(null);

	const clickImage = (id) => {
		const modifiedImageList = imageList.filter(image => image.id !== id)
		const mainImage = imageList.find(image => image.id === id)
		setMainImage(mainImage)
		setimageWaitList(modifiedImageList);
	}

	return (
		<Block className='section'>
			<TitleArea>
				<h2>
					장비소개
				</h2>
			</TitleArea>
			<EquipementArea>
				<MainArea>
					<MainImageArea url={mainImage.url} data-index={mainImage.id}>
					</MainImageArea>
					<ImageListArea>
						{imageWaitList.map(waitImage => {
							return <WaiteImageArea key={waitImage.id} url={waitImage.url} onClick={() => clickImage(waitImage.id)}/>
						})}
					</ImageListArea>
				</MainArea>
				<div style={{ width: '100%', overflowX: 'hidden' }}>
					<AutoSlideArea ref={slideRef}>
						{subImageList.map(image => {
							return (
								<div key={image.id} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
									<AutoSlideImage key={image.id} url={image.url} />
									<EquipName>{image.description}</EquipName>
								</div>
							)
						})}
					</AutoSlideArea>
				</div>
			</EquipementArea>
		</Block>
	)
};

export default Equipment;
