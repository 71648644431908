import React, { useRef } from 'react';
import styled from 'styled-components';
import { media } from '../lib/media';
import Pin from '../components/icon/Pin';
import Car from '../components/icon/Car';
import Divider from '../components/Divider';

const { useEffect } = React;

const Block = styled.div`
	width: 100%;
	height: 100vh;
	max-width: 1000px;
	margin: auto;
	padding: 60px 20px 0 20px;
`

const TitleArea = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 12px;
	margin-bottom: 28px;

	${media.mobile} {
		margin-bottom: 10px;
	}

	img {
		width: 30px;
		height: 30px;

		${media.mobile} {
			width: 22px;
			height: 22px;
		}
	}

	span {
		font-size: 14px;
		color: #666666;
	}

	h2 {
		font-size: 16px;
	}

	${media.mobile} {
		font-size: 16px;
	}
`

const LoadArea = styled.div`
	display: flex;
	justify-content: center;
	gap: 40px;
	margin: auto;
	
	${media.mobile} {
		flex-direction: column;
		gap: 10px;
	}
`

const ContentArea = styled.div`
	width: 100%;
	max-width: 500px;
	display: flex;
	flex-direction: column;
	margin: auto;
`

const HospitalName = styled.div`
	width: fit-content;
	border-top: 4px solid black;

	>h5 {
		padding: 12px 20px 12px 0;
		font-size: 14px;
		font-weight: bold;
	}
`

const Map = styled.div`
	width: 100%;
	max-width: 500px;
	height: 400px;
	margin: auto;

	${media.mobile} {
		height: 250px;
	}
`

const AddressArea = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
	padding: 12px 0;

	span {
		font-size: 14px;
	}

	p {
		font-size: 14px;
	}

	img {
		width: 14px;
		height: 14px;
	}

	${media.mobile} {
		span {
			font-size: 14px;
		}

		p {
			font-size: 14px;
		}

		img {
			width: 12px;
			height: 12px;
		}
	}
`


const TimeTable = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
	padding: 20px 0 20px 26px;

	${media.mobile} {
		padding: 12px 0 12px 26px;
	}

	span {
		font-size: 14px;
	}

	p {
		font-size: 14px;
	}

	img {
		width: 14px;
		height: 14px;
	}
`

const TimeTableArea = styled.div`
	width: 100%;
	display: flex;
	gap: 24px;
`

const Load = () => {
	const mapRef = useRef(null);

	useEffect(() => {
		const { naver } = window;
		if (!mapRef.current || !naver) return;

		const pinPosition = new naver.maps.LatLng(37.482328472687, 126.94429361755)

		const map = new naver.maps.Map(mapRef.current, {
			center: pinPosition,
			zoom: 17,
			scrollWheel: false,
		})

		
		new naver.maps.Marker({
			position: pinPosition,
      map,
    });
	}, [mapRef]);

	return (
		<Block className='section'>
			<TitleArea>
				<img src='/images/gps.png' />
				<h2>오시는 길</h2>
			</TitleArea>
			<LoadArea>
				<Map ref={mapRef} />
				<ContentArea>
					<HospitalName>
						<h5>형제동물의료센터</h5>
					</HospitalName>
					<AddressArea>
						<Pin />
						<div>
							<p style={{ marginBottom: 4 }}>서울 관악구 남부순환로 1747-1 1층</p>
							<p>봉천역 6번 출구에서 140m</p>
						</div>
					</AddressArea>
					<Divider />
					<AddressArea>
						<Car />
						<div>
							<p style={{ marginBottom: 4 }}>주차장: 봉천역 6번출구 주차장</p>
							<p>주차장 이용시 1시간 무료주차쿠폰 드립니다</p>
						</div>
					</AddressArea>
					<Divider />
					<AddressArea>
						<img src='/images/timetable.png' />
						<TimeTableArea>
							<span>월~금</span>
							<span>09:00 ~ 21:00</span>
						</TimeTableArea>
					</AddressArea>
					<Divider />
					<TimeTable>
						<TimeTableArea>
							<span>토요일</span>
							<span>09:00 ~ 19:00</span>
						</TimeTableArea>
					</TimeTable>
					<Divider />
					<TimeTable>
						<TimeTableArea>
							<span>일요일</span>
							<span>10:00 ~ 19:00</span>
						</TimeTableArea>
					</TimeTable>
				</ContentArea>
			</LoadArea>
		</Block>
	)
};

export default Load;