import React, { useState } from "react";
import styled from "styled-components";
import { media } from "../lib/media";
import Slider from 'react-slick';

const Block = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 70px;

	h2 {
		font-size: 24px;
		color: black;
		letter-spacing: -2px;
	}

	h4 {
		font-size: 12px;
		color: #666666;
	}
`

const TitleArea = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 12px;

	img {
		width: 24px;
		height: 24px;
		margin-right: 12px;
	}

	span {
		color: #00498c;
		font-weight: bold;
	}
`

const ImageOutLine = styled.div`
	border-radius: 24px;

	img {
		width: 220px;
		height: 360px;
		border-radius: 24px;
		margin: auto;
	};
`

const ButtonArea = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 20px;
`

const Button = styled.button`
	width: 180px;
	background-color: #00498c;
	padding: 14px;
	border-radius: 12px;
	margin-top: 28px;
	color: white;
	cursor: pointer;

	&:active {
		background-color: #002d57;
	}
`

const Reservation = () => {
	const moveReservation = () => {
		window.location = 'https://web-checkup-c5396.web.app/#/MzA=';
	}

	return (
		<Block className="section">
			<TitleArea>
				<img src='/images/report.png' alt='검진리포트 사진' />
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 24 }}>
					<h4>건강검진 예약 서비스와 리포트까지 한번에</h4>
					<h4><span>형제동물의료센터</span>에서 건강검진받고 리포트를 받아보세요</h4>
				</div>
			</TitleArea>
			<div style={{ width: 280, margin: '0 auto' }}>
				<Slider
					dots={true}
					infinite={true}
					slidesToShow={1}
					draggable={true}
					swipeToSlide={true}
					touchMove={true}
					speed={500}
					slidesToScroll={1}
				>
					<ImageOutLine>
						<img src='/images/reservation/report1.png' />
					</ImageOutLine>
					<ImageOutLine >
					<img src='/images/reservation/report2.png' />
					</ImageOutLine>
					<ImageOutLine>
						<img src='/images/reservation/report3.png' />
					</ImageOutLine>
					<ImageOutLine>
						<img src='/images/reservation/report4.png' />
					</ImageOutLine>
					<ImageOutLine>
						<img src='/images/reservation/report5.png' />
					</ImageOutLine>
					<ImageOutLine>
						<img src='/images/reservation/report6.png' />
					</ImageOutLine>
				</Slider>
			</div>
			<ButtonArea>
				<Button onClick={moveReservation}>예약하기</Button>
			</ButtonArea>
		</Block>
	)
};

export default Reservation;
