import React, { useEffect, useState } from 'react';
import { Link, Scroll, scroller } from 'react-scroll';
import styled from 'styled-components';
import { media } from '../lib/media';

const Block = styled.div`
	width: 100%;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	z-index: 5;
	background-color: white;
	padding: 0 20px;

	${media.mobile} {
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}
`

const LogoArea = styled.div`
	display: flex;
	align-items: center;
	padding: 8px 0;

	img {
		height: 48px;
		margin-right: 8px;
	}

	h3 {
		font-size: 14px;
		font-weight: bold;
	}

	${media.mobile} {
		justify-content: center;

		img {
		}

		h3 {
			display: none;
		}
	}
`

const Header = () => {
	const [position, setPosition] = useState(window.pageYOffset)
	const [showHeader, setShowHeader] = useState(true);

	useEffect(() => {
		const handleScroll = () => {
			let moving = window.pageYOffset;
			const currentWidth = window.innerWidth;
			if (currentWidth < 700) {
				if (moving > 0) {
					setShowHeader(false);
					setPosition(moving)
					return ;
				}
			}

			setShowHeader(true)
		}

		window.addEventListener('scroll', handleScroll)
		return (() => {
			window.removeEventListener('scroll', handleScroll)
		})
	})

	return (
		<Block>
			<LogoArea>
				<img src='/images/bro_logo.jpeg' />
				<span>형제동물의료센터</span>
			</LogoArea>
		</Block>
	)
}

export default Header;