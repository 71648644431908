import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { FullPage, Slide } from 'react-full-page';
import Equipment from './components/Equipment';
import Footer from './components/Footer';
import Header from './components/Header';
import Hospital from './components/Hospital';
import Intro from './components/Intro';
import Load from './components/Load';
import Reservation from './components/Reservation';
import GlobalStyle from './GlobalStyle';
import { theme } from './theme';
import { media } from './lib/media';

const Body = styled.div`
  width: 100vw;
  height: 100vh;

  .fullpage {
    height: 100vh !important;
  }

  ${media.mobile} {
    max-height: 100vh;
  }
`

const App = () => {
  return (
    <Body>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Header />
        <FullPage className='fullpage' duration={1200} >
          <Slide>
            <Intro />
          </Slide>
          <Slide>
            <Reservation />
          </Slide>
          <Slide>
            <Equipment />
          </Slide>
          <Slide>
            <Hospital />
          </Slide>
          <Slide>
            <Load />
          </Slide>
          <Slide>
            <Footer />
          </Slide>
        </FullPage>
      </ThemeProvider>
    </Body>
  );
}

export default App;
