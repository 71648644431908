import { keyframes } from "styled-components";

export const fadeIn = keyframes`
  0% {
		opacity: 0;
		transform: translateY(0);
  }

	10% {
		opacity: 0.2;
		transform: translateY(100px);
	}

  100% {
		opacity: 1;
		transform: translateY(0);
  };
`;

export const rolling = keyframes`
	0% {
		transform: translateX(0);
	}

	100% { transform: translateX(-180%)};
`
