import React from 'react';
import styled from 'styled-components';
import { media } from '../lib/media';
import { fadeIn } from '../lib/transition';

const Block = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	animation: ${fadeIn} 0.5s ease-in-out;

	${media.mobile} {
		height: 100%;
	}
`

const DescriptionArea = styled.div`
	height: 320px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h2 {
		font-size: 36px;
		line-height: 80px;
		color: #666666;
	}

	h3 {
		font-size: 48px;
		line-height: 75px;
		color: #00498c;
		font-weight: bold;
	}

	h5 {
		font-size: 24px;
		color: #666666;
	}

	${media.mobile} {
		h3 {
			font-size: 36px;
		}

		h5 {
			font-size: 20px;
		}
	}
`

const Intro = () => {
	return(
		<Block className='section' >
			<DescriptionArea>
				<h5>반려동물 건강의 핵심</h5>
				<h3>주기적인 건강검진</h3>
			</DescriptionArea>
		</Block>
	)
};

export default Intro;
