import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center, button,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;

	// Font custom
	font-size: 16px;
	font-family: 'pretendardRegular';
	font-weight: 500;
	letter-spacing: -4%;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}

ol, ul, li {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

input {
	border: none
}

// custom global styles 
button {
	background-color: inherit;
	border: none;
}

h1, h2, h3, h4, h5, h6 {
	font-family: "PretendardSemiBold";
}

h1 {
	font-size: 24px;
	line-height: 30px;
}

h2 {
	font-size: 20px;
	line-height: 25px;
}

h3 {
	font-size: 18px;
	line-height: 22.5px;
}

h4 {
	font-size: 17px;
	line-height: 21.3px;
}

h5 {
	font-size: 16px;
	line-height: 20px;
}

h6 {
	font-size: 15px;
	line-height: 18.8px;
}
`;

export default GlobalStyle;
