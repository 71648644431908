import React, { useRef } from 'react';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'
import { media } from '../lib/media';

const { useState } = React;

const Block = styled.div`
	width: 100vw;
	height: 100vh;
	padding-top: 70px;
	display: flex;
	flex-direction: column;
	align-items: center;

	h2 {
		font-size: 22px;
		font-weight: bold;
		color: #00498c;
		letter-spacing: -2px;
	}

	h3 {
		font-size: 16px;
		color: #666666;
	}

	${media.mobile} {
		h2 {
		font-size: 18px;
		font-weight: bold;
		color: #00498c;
		letter-spacing: -2px;
	}

	h3 {
		font-size: 14px;
		color: #666666;
	}
	}
`

const TitleArea = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 12px;

	img {
		width: 30px;
		height: 30px;
	}
`

const ImageArea = styled.div`
	width: 90%;
	max-width: 600px;
	height: 400px;
	margin: 0 auto;

	div {
		margin: 0;
		padding: 0;
	}

	.image-gallery-slide {
		height: 100%;
		max-height: 400px;
	}

	.image-gallery-content {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

 	.image-gallery-thumbnails-container {
		overflow-x: scroll;
		border-radius: 12px;

		img {
			height: 80px;
			opacity: 0.3;
		}
	}

	.image-gallery-thumbnail {
		border: none;
		cursor: pointer;

		&hover {
			border: none;
		}
	}

	.active {
		border: none;

		img {
			opacity: 1;
		}
	}

	.image-gallery-image {
		height: 100%;
	}
`

const hospitalImageList = [
	{
		original: '/images/hospital1.jpeg',
		thumbnail: '/images/hospital1.jpeg'
	},
	{
		original: '/images/hospital2.jpg',
		thumbnail: '/images/hospital2.jpg'
	},
	{
		original: '/images/hospital3.jpg',
		thumbnail: '/images/hospital3.jpg'
	},
	{
		original: '/images/hospital5.jpg',
		thumbnail: '/images/hospital5.jpg'
	},
	{
		original: '/images/hospital6.jpg',
		thumbnail: '/images/hospital6.jpg'
	},
]

const Hospital = () => {
	const sliderRef = useRef();
	const [currentImageIndex, setCurrentImageIndex] = useState(1)

	const imageHandler = (index) => {
		sliderRef.current.slickGoTo(index)
		setCurrentImageIndex(index)
	}

	return (
		<Block className='section' id='hospital'>
			<TitleArea>
				<h2>병원 안내</h2>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 24 }}>
					<h3>환자 중심의 맞춤 진료, 고품질 의료서비스</h3>
					<h3>믿음과 신뢰를 통한 신속하고 정확한 의료서비스를 제공합니다</h3>
				</div>
			</TitleArea>
			<ImageArea>
				<ImageGallery
				 items={hospitalImageList}
				 showNav={false}
				 showFullscreenButton={false}
				 showPlayButton={false}
				/>
			</ImageArea>
		</Block>
	)
};

export default Hospital;
