export const theme = {
	grayColor100: 'var(--color-gray-100)',
	grayColor90: 'var(--color-gray-90)',
	grayColor80: 'var(--color-gray-80)',
	grayColor70: 'var(--color-gray-70)',
	grayColor60: 'var(--color-gray-60)',
	grayColor50: 'var(--color-gray-50)',
	grayColor40: 'var(--color-gray-40)',
	grayColor30: 'var(--color-gray-30)',
	grayColor20: 'var(--color-gray-20)',
	grayColor10: 'var(--color-gray-10)',
	grayColor0: 'var(--color-gray-0)',

	blueColor70: 'var(--color-blue-70)',
	blueColor60: 'var(--color-blue-60)',
	blueColor40: 'var(--color-blue-40)',

	redColor60: 'var(--color-red-60)',
	redColor10: 'var(--color-red-10)',
}

