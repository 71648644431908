import React from 'react';
import styled from 'styled-components';
import { media } from '../lib/media';
import KakaoIcon from './icon/Kakao';

const Block = styled.div`
	width: 100%;
	height: 100vh;
	padding-top: 60px;
`

const Inner = styled.div`
	width: 100vw;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 0 40px;
`

const MainArea = styled.div`
	width: 100%;
	padding: 20px 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 3;

	${media.mobile} {
		flex-direction: column;
	}
`

const ImageArea = styled.div`
	flex: 1;
	img {
		width: 150px;
		height: 150px;
	}
`

const InfoArea = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 20px;

	h1 {
		font-size: 24px;
		font-weight: bold;
	}

	span {
		font-size: 32px;
		font-weight: bold;
		color: #00498c;
		margin-bottom: 24px;
	}
`

const SubArea = styled.div`
	flex: 4;
	width: 100%;
	padding: 0px 40px;
	display: flex;
	flex-direction: column;
	gap: 20px;

	div {
		display: flex;
		flex-direction: column;
		gap: 4px;

		span {
			font-size: 16px;
			color: #666;
		}
	}

`

const SocialLogoArea = styled.div`
	flex: 1;
	padding: 0px 40px;
	display: flex;
	justify-content: space-between;
	width: 300px;
	height: fit-content;

	img {
		width: 36px;
		height: 36px;
		cursor: pointer;
	}
`

const Footer = () => {
	const navigateInstagram = () => {
		window.open('https://instagram.com/cco._.cco?igshid=YmMyMTA2M2Y=')
	}
	const navigateKakao = () => {
		window.open('https://open.kakao.com/o/sIkiYBQb')
	}
	const navigateBlog = () => {
		window.open('https://blog.naver.com/hjamckr')
	}

	return (
		<Block className='section'>
			<Inner>
				<MainArea>
					<ImageArea>
						<img src='/images/bro_logo.jpeg' />
					</ImageArea>
					<InfoArea>
						<h1>형제동물의료센터</h1>
						<span>02-889-0575</span>
					</InfoArea>
					<SocialLogoArea>
						<img src='/images/instagram.png' onClick={navigateInstagram}/>
						<div style={{ cursor: 'pointer' }} onClick={navigateKakao}><KakaoIcon/></div>
						<img src='/images/blog.png' onClick={navigateBlog}/>
					</SocialLogoArea>
				</MainArea>
				<SubArea>
					<div>
						<span>형제동물의료센터</span>
						<span>02-889-0575</span>
					</div>
					<div>
						<span>서울특별시 관악구 남부순환로 1747-1 1층</span>
						<span>봉천동 923-23</span>
					</div>
					<div>
						<span>평일 09:00 ~ 21:00</span>
						<span>토요일 09:00 ~ 19:00</span>
						<span>공휴일 10:00 ~ 19:00</span>
					</div>
				</SubArea>
			</Inner>
		</Block>
	)
};

export default Footer;
