import React from 'react';

const CarIcon = () => {
	return (
		<svg width="14" height="20" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M15.92 1.01C15.72 0.42 15.16 0 14.5 0H3.5C2.84 0 2.29 0.42 2.08 1.01L0.11 6.68C0.0399999 6.89 0 7.11 0 7.34V14.5C0 15.33 0.67 16 1.5 16C2.33 16 3 15.33 3 14.5V14H15V14.5C15 15.32 15.67 16 16.5 16C17.32 16 18 15.33 18 14.5V7.34C18 7.12 17.96 6.89 17.89 6.68L15.92 1.01ZM3.5 11C2.67 11 2 10.33 2 9.5C2 8.67 2.67 8 3.5 8C4.33 8 5 8.67 5 9.5C5 10.33 4.33 11 3.5 11ZM14.5 11C13.67 11 13 10.33 13 9.5C13 8.67 13.67 8 14.5 8C15.33 8 16 8.67 16 9.5C16 10.33 15.33 11 14.5 11ZM2 6L3.27 2.18C3.41 1.78 3.79 1.5 4.22 1.5H13.78C14.21 1.5 14.59 1.78 14.73 2.18L16 6H2Z" fill="black"/>
		</svg>
	)
}

export default CarIcon;
