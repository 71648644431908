import React from 'react';
import styled from 'styled-components';

const Block = styled.div`
	width: 100%;
	height: 1px;
	background-color: hsl(0, 0%, 85%);
`

const Divider = () => {
	return <Block />
}

export default Divider;